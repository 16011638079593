import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp, library } from '@fortawesome/fontawesome-svg-core'
import { faArrowsLeftRightToLine, faCheck, faFlag, faHandshake, faHashtag, faMinus, faPersonRays, faPersonWalkingArrowLoopLeft,
	faRightLong, faSignature, faStrikethrough, faUserTag, faWandMagicSparkles, faXmark } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../contexts/AppContext'
import { HELP_SECTIONS, HelpSection, HelpSectionExampleRow } from './ExploreHelpSections'
import { AppLevelAction } from '../helpers/action/AppLevelAction.d'
import { Link } from 'react-router-dom'
library.add(faArrowsLeftRightToLine, faCheck, faFlag, faHandshake, faHashtag, faPersonRays, faPersonWalkingArrowLoopLeft,
	faRightLong, faSignature, faStrikethrough, faUserTag, faWandMagicSparkles, faXmark, faMinus)

export const toggleExploreHelpSection = (openSections: string[], toggledSection: string): string[] => {
	if (openSections.includes(toggledSection)) {
		return openSections.slice(0, openSections.indexOf(toggledSection))
	}
	for (const section of openSections) {
		// if we toggled a child entry of one that's already open
		if (HELP_SECTIONS[section]?.subsections?.includes(toggledSection)) {
			const result = openSections.slice(0, openSections.indexOf(section) + 1)
			result.push(toggledSection)
			return result
		}
	}
	return [toggledSection]
}

type ChangedStatus = 'skipped' | 'changed' | 'matched' | 'effect'

const statuses: Record<ChangedStatus, { icon: IconProp, description: string }> = {
	'skipped': { icon: 'xmark', description: 'Entry text is not matched' },
	'changed': { icon: 'wand-magic-sparkles', description: 'Result is modified from what was written' },
	'matched': { icon: 'check', description: 'The text is recognised and can be referenced unchanged' },
	'effect': { icon: 'right-long', description: 'The text will be processed as described' }
}

const getStatus = (text: string, result: string | JSX.Element | undefined): ChangedStatus => {
	if (!result) {
		return 'skipped'
	} else if (typeof result !== 'string') {
		return 'changed'
	}
	return result === text
		|| result === text.replace(/[()]/g, '')
		|| result === text.replace(/([A-Z]{2}) [(](.*)[)]/, ' $2 ($1)')
		|| result === text.replace(/(.+)([A-Z]{2})$/, '$1 ($2)')
		|| ['#', '@'].includes(result.charAt(0)) && result.toLocaleLowerCase() == text.toLocaleLowerCase()
		? 'matched'
		: 'changed'
}

const setNotification = (message: string, icon: IconProp | undefined): AppLevelAction => ({
	type: 'SET_NOTIFICATION_TEXT', message, icon, flipped: icon === 'wand-magic-sparkles' ? true : undefined
})

const ExploreExampleAction = (props: {action: ChangedStatus}): JSX.Element => {
	const { appDispatch } = useContext(AppContext)
	const status = statuses[props.action]
	const hover = () => appDispatch(setNotification(status.description, status.icon))
	const leave = () => appDispatch({ type: 'CLEAR_NOTIFICATION_TEXT' })
	return <FontAwesomeIcon className='explore-help-tip' icon={status.icon}
		flip={status.icon === 'wand-magic-sparkles' ? 'horizontal' : undefined}
		onMouseOver={hover} onTouchStart={hover} onMouseOut={leave} onTouchEnd={leave} onTouchCancel={leave} />
}

const ExampleCell = (props: {
	children: JSX.Element | string | undefined,
	title?: boolean,
	tip?: string,
	status?: ChangedStatus}
): JSX.Element => {
	const { appDispatch } = useContext(AppContext)
	const statusIcon = props.status ? statuses[props.status].icon : undefined
	const hover = props.tip
		? () => appDispatch(setNotification(`Note: ${props.tip}`, statusIcon))
		: undefined
	const leave = props.tip ? () => appDispatch({ type: 'CLEAR_NOTIFICATION_TEXT' }) : undefined
	return <>
		{props.title
			? <th>{props.children}</th>
			: <td className={props.tip ? 'explore-help-tip' : ''}
				onMouseOver={hover} onTouchStart={hover} onMouseOut={leave} onTouchEnd={leave} onTouchCancel={leave}
			>{props.children}</td>}
		{props.tip === undefined
			? <></>
			: props.tip
				? <td className='explore-help-tip'
					onMouseOver={hover} onTouchStart={hover} onMouseOut={leave} onTouchEnd={leave} onTouchCancel={leave}
				><FontAwesomeIcon icon='info-circle' /></td>
				: <td />}
	</>
}

const ExploreHelpExampleRow = (props: {
	values: HelpSectionExampleRow,
	config: HelpSection['exampleConfig'],
	title?: boolean }
): JSX.Element => {
	const status = props.config?.isToResult ? 'effect' : getStatus(props.values.from, props.values.to)
	return <tr className={props.values.separator ? ' separator' : ''}>
		<ExampleCell title={props.title}>{props.values.from}</ExampleCell>
		{ props.values.date && <ExampleCell title={props.title}>{props.values.date}</ExampleCell>}
		<ExampleCell>{props.title ? undefined : <ExploreExampleAction action={status} />}</ExampleCell>
		<ExampleCell title={props.title} tip={props.values.description} status={status}
		>{props.values.to || <FontAwesomeIcon icon='minus' />}</ExampleCell>
	</tr>
}

const ExploreHelpExample = (props: { example: HelpSectionExampleRow[], config: HelpSection['exampleConfig'] }): JSX.Element =>
	<table>
		<thead><ExploreHelpExampleRow values={props.example[0]} config={props.config} title={true} /></thead>
		<tbody>{props.example.slice(1).map((row, i) => <ExploreHelpExampleRow values={row} config={props.config} key={i} />)}</tbody>
	</table>

const ExploreHelpSection = (props: typeof HELP_SECTIONS[string] & { section: string }) => {
	const { appDispatch, appState } = useContext(AppContext)
	if (props.draft) {
		return <></>
	}
	const shown = appState.explore?.help && appState.explore.help.includes(props.section)
	return <div id={`explore-help-${props.section}`} className='explore-help'>
		<h3 onClick={() => appDispatch({ type: 'TOGGLE_HELP', section: props.section })}>
			{props.icon && <FontAwesomeIcon icon={props.icon} />} {props.title} {}
			<FontAwesomeIcon className='clickable' icon={shown ? 'square-caret-down' : 'square-caret-right'} />
		</h3>
		<div className={`explore-help-section ${shown ? '' : 'explore-help-section-hidden'}`}>
			<div className='explore-help-text'>{typeof props.text === 'function' ? props.text(appDispatch) : props.text}</div>
			{ props.example && <ExploreHelpExample example={props.example} config={props.exampleConfig} />}
			{ props.subsections?.some(subsection => !HELP_SECTIONS[subsection].draft) && <>
				<h4>Explore further:</h4>
				{ props.subsections.map(subsection => !HELP_SECTIONS[subsection].draft &&
					<div className='indented' key={subsection}>
						<ExploreHelpSection {...HELP_SECTIONS[subsection]} section={subsection} />
					</div>) }
			</> }
		</div>
	</div>
}

// TODO give more info depending on how many entries you currently have.
export const ExploreHelp = (): JSX.Element => {
	// TODO remove the existing help page once this is fairly complete
	// TODO faRankingStar feels like a useful icon. Maybe also file-pen?
	return <div className='explore-help-text'>
		<p>
			Once you have written a few entries, you can begin to leverage the full power of Trove.
			Your entries are periodically
			{' '}<Link to='/settings'><FontAwesomeIcon icon='magnifying-glass-chart'/> analysed</Link>
			{' '}(don’t worry — no information is sent to third parties!)
			and the results can be viewed here.
		</p>
		<p>There are several ways to curate and view the insights provided.</p>
		{Object.keys(HELP_SECTIONS).map(section => HELP_SECTIONS[section].toplevel && <ExploreHelpSection
			{...{ ...HELP_SECTIONS[section] }} key={section} section={section} />)}
	</div>
}
