import React from 'react'
import { IconProp, library } from '@fortawesome/fontawesome-svg-core'
import { faArrowsLeftRightToLine, faCheck, faFlag, faHashtag, faLink, faPersonRays, faPersonWalkingArrowLoopLeft, faSignature,
	faStrikethrough, faUserTag, faWandMagicSparkles, faXmark, faScrewdriverWrench, faSpellCheck,
	faCrosshairs } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, PrimaryAction } from '../helpers/action/Action.d'
library.add(faArrowsLeftRightToLine, faCheck, faFlag, faHashtag, faLink, faPersonRays, faPersonWalkingArrowLoopLeft, faSignature,
	faStrikethrough, faUserTag, faWandMagicSparkles, faXmark, faScrewdriverWrench, faSpellCheck, faCrosshairs)

export type HelpSectionExampleRow = {
	from: string
	to?: string | JSX.Element
	date?: string | JSX.Element
	description?: string
	/** Show a faint separator line above this row */
	separator?: true
}

export type HelpSection = {
	text:  string | JSX.Element | ((appDispatch: Dispatch<PrimaryAction>) => JSX.Element)
	title: string
	icon?: IconProp
	subsections?: string[]
	toplevel?: true
	/** Don't show this on the Help page itself */
	draft?: true
	exampleConfig?: {
		isToResult?: boolean
	}
	example?: HelpSectionExampleRow[]
}

export const HELP_SECTIONS: Record<string, HelpSection> = {

	/** WORDS **/
	words: {
		icon: 'spell-check',
		title: 'Words',
		text: (appDispatch) => <>
			<p>The simplest analysis Trove provides is on the words you write.</p>
			<p>
				Once you have a few entries, you’ll be able to view wordclouds of the words
				used particularly often in the recent past (default: the last month) on the{' '}
				<span className='clickable' onClick={() => appDispatch({ type: 'EXPLORE_SELECT_TAB', tab: 'recent' })}>
					<FontAwesomeIcon  icon='clock-rotate-left' />{' '} Recent
				</span> tab.
			</p>
			<p>Note that this will show words used <em>disproportionally often</em> during this time,
				relative to the norm across all entries:</p>
		</>,
		toplevel: true,
		exampleConfig: {
			isToResult: true
		},
		example: [
			{ from: 'Entry text', date: 'How often used', to: 'Appearance in wordclouds' },
			{ from: 'family', date: 'regularly and consistently', to: 'Usually not shown' },
			{ from: 'work', date: 'regularly, with peaks and troughs', to: 'Shown during busy work times' },
			{ from: 'fun', date: 'irregularly, with peaks and troughs', to: 'Particularly prominent when it appears' }
		]
	},

	/** TAGS **/
	tags: {
		icon: 'hashtag',
		title: 'Hashtags and handles',
		text: <><p>Trove offers several ways to track specific concepts.</p>
			<p>The most flexible of these is <em>#hashtags</em> or <em>@handles</em> (collectively: <em>tags</em>).
				These function similarly to those on social media, except that there is
				no functional difference within Trove between a hashtag and a handle.</p>
			<p>They can be viewed on the Explore page, either in wordclouds or individually.
				Each hashtag and handle is tracked for how prominent it has been over time,
				and which others appear in the same entries as it.</p>
			<p>An important feature of Trove is that once a word or phrase has been used as a hashtag,
				it will be tracked even without the hashtag, throughout the whole text.
				This allows you to mark interesting words or phrases once — with a single hashtag — and otherwise write normally.
				For multi-word tags this is case sensitive, as shown:</p></>,
		subsections: ['tagLinks'],
		toplevel: true,
		example: [
			{ from: 'Entry text', to: 'Tag' },
			{ from: '#birthday', to: '#birthday' },
			{ from: 'Birthday', to: '#birthday', description: 'this word matches the existing tag, ignoring upper/lowercase' },
			{ from: 'Jane', to: '@Jane', description: 'this matches @Jane irrespective of where in the diary they each appear' },
			{ from: '@Jane', to: '@Jane' },
			{ from: '@JanesDad', to: '@JanesDad', description: 'the capitalisation indicates that these are two separate words' },
			{ from: "Jane's dad", to: '@JanesDad', description: 'these words can match the two-word tag specified elsewhere' },
			{ from: '#whynot', to: '#whynot', description: 'no capitalisation means this is treated as a one-word tag' },
			{ from: 'why not', to: '', description: "with #whynot being treated as one word, it doesn't match these two separate ones" }
		]
	},
	tagLinks: {
		icon: 'link', // or 'handshake'
		title: 'Tag links',
		text: <>
			<p>
				For any given tag, you can view others that appear in the same entries.
				This can give an idea of how closely related the two tags are for you.
			</p>
			<p>In most cases, links are stronger when the words appear many times together anywhere within entries.
				This could mean both appearing a lot within a few entries;
				both appearing a few times within a large number of entries, usually together;
				or both appearing commonly throughout all entries.
			</p>
			<p>
				For stronger, permanent links you can include a <em>!</em> symbol to mark that the given link
				is inherently tied to all others <em>on the same line</em> within the given entry.
				This link is then independent of the amount they occur together, and can only be reset
				by editing the entry to remove the <em>!</em>.
			</p>
		</>,
		exampleConfig: {
			isToResult: true
		},
		example: [
			{ from: 'Entry text', to: 'Result' },
			{ from: 'ate #food with #family', to: "'food' and 'family' will be linked if they appear together a lot" },
			{ from: '#family time: #food food food', to: "'food' will will be be more strongly linked with 'family'" },
			{ from: 'saw #family: #!cousins', to: "'family' and 'cousins' will be forever tightly linked" }
		]
	},

	/** NAMES **/
	names: {
		icon: 'signature',
		title: 'Names',
		text: <>
			<p>If you reference enough people that tags become unwieldy, Trove has a solution: names!</p>
			<p>
                This feature allows you to differentiate between people by surname or by country,
                without needing to write that information into every entry. Instead, just write
                a first name, and Trove will figure out who it refers to.
			</p>
			<p>
                All you need is to write someone’s full name in “canonical” form once: with proper capitalisation, and the surname
                in brackets. This is to prevent Trove finding names where there are none,
                like <em>Costa Coffee</em> or <em>Greater London</em>.
			</p>
			<p>
                You can then reference the name either in full (with or without the brackets), or simply by the first name.
                This can be anywhere in the diary — before or after the “canonical” reference.
                The only limitation that you must still capitalise it; otherwise there would be no way to differentiate
                Hope (a person) from hope (the concept) — or indeed Bob, Grace or Jack!
			</p>
		</>,
		toplevel: true,
		draft: true,
		subsections: ['nameMatches', 'countries', 'namesFirsts', 'titles'],
		example: [
			{ from: 'Entry text', to: 'Name' },
			{ from: 'John (Smith)', to: 'John Smith' },
			{ from: 'John Smith', to: 'John Smith', description: 'this is recognised because John (Smith) exists elsewhere' },
			{ from: 'Fred Jones', description: 'Fred (Jones) doesn’t exist in any entry, so this is not recognised' },
			{ from: 'John', to: 'John Smith', description: 'this matches John (Smith)' },
			{ from: 'john', to: '', description: 'this lowercase text can’t match any name' },
			{ from: 'Jane (Smith-Jones)', to: 'Jane Smith-Jones',
				description: 'hyphenated canonical names are recognised' },
			{ from: 'Pierre (de la Ville)', to: 'Pierre de la Ville',
				description: 'certain non-capitalised words are supported within surnames' },
			{ from: 'jake (smith)', to: '', description: 'canonical names, like first names, cannot be all in lowercase' }
		]
	},
	nameMatches: {
		icon: 'crosshairs',
		title: 'Precise matching rules',
		text: <>
			<p>The way Trove matches names to surnames is mostly very simple: by date.</p>
			<p>
                If a first name exists on the same day as, or any time after, a full name, it’ll match it — until another
                full name is mentioned which will be matched instead.
                Only if there’s no full name <em>before</em> a first name will Trove match based on later entries.
			</p>
			<p>
                If two full names are mentioned on the same day, Trove applies similar logic within that day
                — the most recently mentioned surname will be matched — but then assumes the doubling-up was unusual
                and ignores the day when considering later matches.
			</p>
		</>,
		example: [
			{ from: 'Entry text', date: 'Entry', to: 'Matches' },
			{ from: 'Anna', date: 'Day 3', to: 'Anna Smith',
				description: 'Trove matches forward to day 5 only because there’s no full name before this' },
			{ from: 'Anna (Smith)', date: 'Day 5', to: 'Anna Smith' },
			{ from: 'Anna', date: 'Day 12', to: <>Anna <strong>Jones</strong></>, separator: true,
				description: 'Anna Jones’ surname is mentioned in the same entry, albeit later on' },
			{ from: 'Anna (Jones)', date: 'Day 12', to: 'Anna Jones' },
			{ from: 'Anna', date: 'Day 20', to: 'Anna Jones', separator: true,
				description: 'this matches Anna Jones from day 12' },
			{ from: 'Anna Smith', date: 'Day 20', to: 'Anna Smith' },
			{ from: 'Anna Jones', date: 'Day 20', to: 'Anna Jones' },
			{ from: 'Anna Smith', date: 'Day 20', to: 'Anna Smith' },
			{ from: 'Anna', date: 'Day 20', to: 'Anna Smith' },
			{ from: 'Anna', date: 'Day 40', to: <>Anna <strong>Jones</strong></>, separator: true,
				description: 'ignoring day 20, Trove matches Anna Jones from day 12' }
		]
	},
	countries: {
		icon: 'flag',
		title: 'Country codes',
		draft: true,
		text: <>
			<p>
                You may find it helpful to note down where someone is from.
                Indeed, sometimes, if you don’t know their surname, this may be the easiest way to refer to them.</p>
			<p>
                Within Trove, you can include two- or three-letter country codes in a name. Any codes are permitted, but we recommend
                using <a target="_blank" rel="noreferrer" href="https://en.wikipedia.org/wiki/ISO_3166-1#Codes">ISO 3166-1</a> codes
                to avoid ambiguity.
                Trove will recognise any country code that is used within a “canonical” name elsewhere,
                or attached to a first name it has seen.
			</p>
			<p>These can then be used similarly to, and alongside, surnames and canonical names to clarify who a first name refers to.</p>
		</>,
		example: [
			{ from: 'Entry text', to: 'Name' },
			{ from: 'JohnGB (Smith)', to: 'John Smith (GB)' },
			{ from: 'JohnUS (Doe)', to: 'John Doe (US)' },
			{ from: 'JohnGB', to: 'John Smith (GB)' },
			{ from: 'John', to: 'John Smith (GB)' },
			{ from: 'JaneGB', to: 'Jane ??? (GB)',
				description: "as GB already matches another name, it's used to assume that Jane is a new person from there." },
			// TODO check real behaviour for these two lines (^ and v), and make the examples clear
			{ from: 'Jane (Smith)', to: 'Jane Smith (GB)' },
			{ from: 'JaneIE', to: 'JaneIE (???)' }, // TODO does this work, marking IE as valid because Jane is known?
			{ from: 'EffieEX', description: 'neither Effie nor the country EX are recognised, so this is not considered a name.' }
		]
	},
	namesFirsts: {
		icon: 'arrows-left-right-to-line',
		title: 'First-time names',
		draft: true,
		text: '',
		example: [
			{ from: 'Entry text', date: 'Entry', to: 'Name' },
			{ from: 'John (Smith)', date: 'Day 1', to: 'John Smith' },
			{ from: 'John-1', date: 'Day 2', to: 'John Jones' },
			{ from: 'John (Jones)', date: 'Day 3', to: 'John Jones' },
			{ from: 'John-1', date: 'Day 4', to: 'John ???' },
			{ from: 'John-1 (Smythe)', date: 'Day 5', to: 'John Smythe' }
		]
	},
	titles: {
		icon: 'person-rays',
		title: 'Titles',
		text: 'Note that this only supports specific titles: list is <see config>',
		draft: true,
		example: [
			{ from: 'Entry text', to: 'Name' },
			{ from: 'Sir Galahad', to: 'Sir Galahad' },
			{ from: 'Galahad', to: 'Sir Galahad' },
			{ from: 'Count BinfaceUK', to: 'Count Binface (UK)', description: 'country codes are also supported' }
		]
	},

	/** ALIASES **/
	aliases: {
		title: 'Automatic corrections',
		icon: 'screwdriver-wrench',
		text: '',
		toplevel: true,
		draft: true,
		subsections: ['typos', 'nicknames']
	},
	typos: {
		icon: 'strikethrough',
		title: 'Typo detection',
		text: '',
		example: [
			{ from: 'Entry text', to: 'Name' },
			{ from: 'John (Smith)', to: 'John Smith' },
			{ from: 'John', to: 'John Smith' },
			{ from: 'Johm', to: 'John Smith' }
		]
	},
	nicknames: {
		icon: 'person-walking-arrow-loop-left', // user-tag or person-walking-arrow-loop-left?
		title: 'Nicknames',
		text: '',
		example: [
			{ from: 'Entry text', to: 'Name' },
			{ from: 'Katie (Smith)', to: 'Katie Smith' },
			{ from: 'Katy (Smith)', to: 'Katie Smith' },
			{ from: 'Katrina (Smith)', to: 'Katie Smith' }
		]
	}
}
